/* General Reset */
body, h1, h3, h5, p, ul, li, a {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  color: #333;
  background: #f8f9fa;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  background: #fff;
}

/* Sidebar */
.sidebar {
  background: #2b2d42;
  color: #fff;
  width: 250px;
  padding: 20px;
  transition: transform 0.3s ease;
}

.sidebar h1, .sidebar h5 {
  text-align: center;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
  padding: 10px 15px;
  display: block;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.sidebar ul li a:hover {
  background: #8d99ae;
}

/* Mobile Menu Toggle */
.menu-toggle {
  display: none;
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.menu-toggle span {
  display: block;
  width: 30px;
  height: 3px;
  background: #333;
  margin: 5px 0;
  transition: transform 0.3s ease, background 0.3s ease;
}

/* For Mobile */
@media (max-width: 768px) {
  /*
  .sidebar {
    transform: translateX(-100%);
    position: fixed;
    height: 100%;
  }

  .menu-toggle {
    display: block;
  }

  .sidebar.active {
    transform: translateX(0);
  }
  */
  
  .menu-toggle {
    display: block;
    margin-left: 5px;
  }

  .sidebar {
    display: none;
    flex-direction: column;
    width: 50%;
    position: absolute;
    top: 60px; /* Adjust as needed */
    left: 0;
    background: #2b2d42;
    color: #fff;
  }

  .sidebar.show {
      display: flex;
  }

  .sidebar li {
      margin: 0;
      text-align: left;
      padding: 10px;
      border-bottom: none;
  }

  .sidebar a {
      display: block;
  }

  .sidebar a:hover {
      background-color: #555;
  }

}

/* Content */
.content {
  flex: 1;
  padding: 20px;
  max-width: 100%;
}

.content #hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #edf2f4;
  padding: 40px 20px;
  border-radius: 10px;
}

#hero img {
  width: 120px;
  height: 120px;
  object-fit: cover; /* Ensures the image is cropped to fit if necessary */
  border-radius: 50%;
  margin-bottom: 15px;
}

#hero h1 {
  font-size: 2em;
  color: #2b2d42;
}

#hero h3 {
  font-size: 1.2em;
  color: #8d99ae;
  margin-top: 10px;
}

.content section {
  margin: 20px 0;
  padding: 15px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content section p {
  font-size: 1em;
  color: #333;
}

.content section a.hyperlink {
  color: #ef233c;
  text-decoration: none;
  font-weight: 600;
}

.content section a.hyperlink:hover {
  text-decoration: underline;
}
